<template>
  <div class="mt-5 mb-5">
    <div class="card-wrapper">
      <card
        v-for="(card, index) in cards"
        :key="index"
        :title="card.title"
        :subtitle="card.subtitle"
        :author="card.author"
        :videos="card.videos"
        :hours="card.hours"
        :image="card.image"
        :price="card.price"
        :edit="false"
      />
    </div>
  </div>
</template>

<script>
import Card from '../components/Card.vue'
export default {
  components: { Card },
  name: 'Courses',
  data: () => ({
    cards: [
      {
        title: 'Full Vue.js development course',
        subtitle:
          'In this course you will create a commercial project using Vue, VueX, Router, Firebase',
        author: 'Jhon Silver',
        videos: 15,
        hours: 22,
        image:
          'https://timeweb.com/ru/community/article/1d/1d959c23e81024374895da086675b298.jpg',
        price: '300$',
      },
      {
        title: 'German Course - Upper Intermediate',
        subtitle:
          'Learn German Language with a Native Teacher. German speaking, German grammar, vocabulary: business, job, cv, interviews',
        author: 'Adolf Brisch',
        videos: 46,
        hours: 113,
        image:
          'https://www.kindernetz.de/wissen/1611230920701,europa-deutschland-102~_v-16x9@2dL_-6c42aff4e68b43c7868c3240d3ebfa29867457da.jpg',
        price: '250$',
      },
      {
        title: 'The Self-Improvement Blueprint',
        subtitle:
          'Achieve clarity and meaning in your life -The ultimate self-help masterclass to become who you could be',
        author: 'Lola Iver',
        videos: 64,
        hours: 67,
        image:
          'https://www.meme-arsenal.com/memes/8dfa9a264428fbf7cbf2bf052ea15998.jpg',
        price: '619$',
      },
      {
        title: 'Become a Product Manager',
        subtitle:
          'The most complete course available on Product Management. 13+ hours of videos, activities, interviews, & more',
        author: 'Pete Mepfi',
        videos: 13,
        hours: 25,
        image:
          'https://lh4.googleusercontent.com/3VhQIXkoLhZ05wesO9tD5Hdb07ERkEsN2ZP5zFFQ1Kp57IQEYtYyfZL1o0KWndDm2drUk01VQvH-aE4kIxGwkn7XPBEic-nclM-sEZsNoJDsVYP6F_iGcqhWtewZAWP0V1QY-DLR',
        price: '79$',
      },
      {
        title: 'Complete Guitar Lessons System - Beginner to Advanced',
        subtitle:
          'All-in-one Guitar Course, Fingerstyle Guitar, Blues Guitar, Acoustic Guitar, Electric Guitar & Fingerpicking Guitarra',
        author: 'Duccio Alabiso',
        videos: 125,
        hours: 40,
        image: 'https://www.businesscoot.com/uploads/study_main_image/227.webp',
        price: '79$',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 75vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}
</style>
