<template>
  <div class="card pa-10">
    <div class="card-photo">
      <img :src="image" alt="" />
    </div>
    <div class="card-text">
      <div class="card-main">
        <h1 class="display-1">{{ title }}</h1>
        <p class="subheading font-weight-regular mb-2">
          {{ subtitle }}
        </p>
        <p class="subheading font-weight-bold mb-2">by {{ author }}</p>
        <p class="subheading font-weight-light mb-2">
          {{ hours }} hours | {{ videos }} videos
        </p>
        <v-btn
          v-if="!edit"
          elevation="2"
          color="teal accent-4"
          class="mr-2 white--text"
          >Open</v-btn
        >
        <v-btn
          v-if="edit"
          elevation="2"
          class="mr-2 white--text"
          color="teal accent-4"
          >Edit</v-btn
        >
      </div>
      <div class="subheading font-weight-bold ml-2">{{ price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: [
    'title',
    'subtitle',
    'author',
    'hours',
    'videos',
    'image',
    'price',
    'edit',
  ],
  data: () => ({
    //
  }),
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: row;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  margin: 10px;
  width: 100%;
  min-height: 250px;
  text-align: left;
  -webkit-box-shadow: 2px 6px 8px 6px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 6px 8px 6px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 6px 8px 6px rgba(34, 60, 80, 0.2);

  &-photo {
    width: 350px;
    height: auto;

    img {
      width: 350px;
      height: auto;
    }
  }

  &-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
  }
}

.subheading {
  margin: 0;
}
</style>
